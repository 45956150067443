
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import InformationDDC from "./InformationDDC.vue";
import formatUtil from "../../mixins/roundValues";
import highlightReports from "../../mixins/highlightReports";
import { formatFinancialAmounts, formatTwoDecimal} from '../../utils/format'

export default Vue.extend({
  name: "vWD-ProductUse",
  mixins: [formatUtil, highlightReports],
  components: { InformationDDC },
  data: () => ({
    data: [],
    isLoading: false,
    bleedingDisorderSelected: [],
    bleedingDisorders: [],
    payload: {
      year: null,
      diseaseType: [] as number[],
    },
  }),
  computed: {
    maxtableHeight() {
      return window.screen.height - window.screen.height * 0.20;
    },
    title() {
      return i18n.t("reports.ddcreports.listReports.vWDProductUse");
    },
    yearSelected() {
      return this.$store.getters["ddcreport/getYearSelected"].year;
    },
    headers() {
      return [
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.n"),
          value: "n",
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.country"),
          value: "country",
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.region"),
          value: "region",
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.surveyYear"),
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.dataYear"),
          value: "dataYear",
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previousSurveyYear"),
          value: "previousSurveyYear",
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previousDataYear"),
          value: "previousDataYear",
        },
        //==================== Plasma ====================
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_Plasma_Total"),
          value: "current_Plasma_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Plasma_Total"
          ),
          value: "previous_Plasma_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.plasma_Count_Perc_Diff"
          ),
          value: "plasma_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_Plasma_Male"),
          value: "current_Plasma_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Plasma_Male"
          ),
          value: "previous_Plasma_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.plasma_Male_Count_Perc_Diff"
          ),
          value: "plasma_Male_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_Plasma_Female"),
          value: "current_Plasma_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Plasma_Female"
          ),
          value: "previous_Plasma_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.plasma_Female_Count_Perc_Diff"
          ),
          value: "plasma_Female_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_Plasma_Unknown"),
          value: "current_Plasma_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Plasma_Unknown"
          ),
          value: "previous_Plasma_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.plasma_Unknown_Count_Perc_Diff"
          ),
          value: "plasma_Unknown_Count_Perc_Diff",
          aling: "center",
        },
        //==================== Cryoprecipitate ====================
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Cryoprecipitate_Total"
          ),
          value: "current_Cryoprecipitate_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Cryoprecipitate_Total"
          ),
          value: "previous_Cryoprecipitate_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.cryoprecipitate_Count_Perc_Diff"
          ),
          value: "cryoprecipitate_Count_Perc_Diff",
          aling: "center",

        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Cryoprecipitate_Male"
          ),
          value: "current_Cryoprecipitate_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Cryoprecipitate_Male"
          ),
          value: "previous_Cryoprecipitate_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.cryoprecipitate_Male_Count_Perc_Diff"
          ),
          value: "cryoprecipitate_Male_Count_Perc_Diff",
          aling: "center",

        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Cryoprecipitate_Female"
          ),
          value: "current_Cryoprecipitate_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Cryoprecipitate_Female"
          ),
          value: "previous_Cryoprecipitate_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.cryoprecipitate_Female_Count_Perc_Diff"
          ),
          value: "cryoprecipitate_Female_Count_Perc_Diff",
          aling: "center",

        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Cryoprecipitate_Unknown"
          ),
          value: "current_Cryoprecipitate_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Cryoprecipitate_Unknown"
          ),
          value: "previous_Cryoprecipitate_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.cryoprecipitate_Unknown_Count_Perc_Diff"
          ),
          value: "cryoprecipitate_Unknown_Count_Perc_Diff",
          aling: "center",

        },
        //==================== PDC ====================
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_PDC_Total"),
          value: "current_PDC_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_PDC_Total"),
          value: "previous_PDC_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.pdC_Count_Perc_Diff"),
          value: "pdC_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_PDC_Male"),
          value: "current_PDC_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_PDC_Male"),
          value: "previous_PDC_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.pdC_Male_Count_Perc_Diff"),
          value: "pdC_Male_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_PDC_Female"),
          value: "current_PDC_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_PDC_Female"),
          value: "previous_PDC_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.pdC_Female_Count_Perc_Diff"),
          value: "pdC_Female_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_PDC_Unknown"),
          value: "current_PDC_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_PDC_Unknown"),
          value: "previous_PDC_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.pdC_Unknown_Count_Perc_Diff"),
          value: "pdC_Unknown_Count_Perc_Diff",
          aling: "center",
        },
        //==================== RC ====================
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_RC_Total"),
          value: "current_RC_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_RC_Total"),
          value: "previous_RC_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.rC_Count_Perc_Diff"),
          value: "rC_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_RC_Male"),
          value: "current_RC_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_RC_Male"),
          value: "previous_RC_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.rC_Male_Count_Perc_Diff"),
          value: "rC_Male_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_RC_Female"),
          value: "current_RC_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_RC_Female"),
          value: "previous_RC_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.rC_Female_Count_Perc_Diff"),
          value: "rC_Female_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_RC_Unknown"),
          value: "current_RC_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_RC_Unknown"),
          value: "previous_RC_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.rC_Unknown_Count_Perc_Diff"),
          value: "rC_Unknown_Count_Perc_Diff",
          aling: "center",
        },
        //==================== DDAVP ====================
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_DDAVP_Total"),
          value: "current_DDAVP_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_DDAVP_Total"),
          value: "previous_DDAVP_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_Count_Perc_Diff"
          ),
          value: "ddavP_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_DDAVP_Male"),
          value: "current_DDAVP_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_DDAVP_Male"),
          value: "previous_DDAVP_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_Male_Count_Perc_Diff"
          ),
          value: "ddavP_Male_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_DDAVP_Female"),
          value: "current_DDAVP_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_DDAVP_Female"),
          value: "previous_DDAVP_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_Female_Count_Perc_Diff"
          ),
          value: "ddavP_Female_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t("reports.ddcreports.vWDProductUse.current_DDAVP_Unknown"),
          value: "current_DDAVP_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.vWDProductUse.previous_DDAVP_Unknown"),
          value: "previous_DDAVP_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_Unknown_Count_Perc_Diff"
          ),
          value: "ddavP_Unknown_Count_Perc_Diff",
          aling: "center",
        },
        //==================== DDAVP Nasal ====================
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_DDAVP_nasal_Total"
          ),
          value: "current_DDAVP_nasal_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_DDAVP_nasal_Total"
          ),
          value: "previous_DDAVP_nasal_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_nasal_Count_Perc_Diff"
          ),
          value: "ddavP_nasal_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_DDAVP_nasal_Male"
          ),
          value: "current_DDAVP_nasal_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_DDAVP_nasal_Male"
          ),
          value: "previous_DDAVP_nasal_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_nasal_Male_Count_Perc_Diff"
          ),
          value: "ddavP_nasal_Male_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_DDAVP_nasal_Female"
          ),
          value: "current_DDAVP_nasal_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_DDAVP_nasal_Female"
          ),
          value: "previous_DDAVP_nasal_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_nasal_Female_Count_Perc_Diff"
          ),
          value: "ddavP_nasal_Female_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_DDAVP_nasal_Unknown"
          ),
          value: "current_DDAVP_nasal_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_DDAVP_nasal_Unknown"
          ),
          value: "previous_DDAVP_nasal_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_nasal_Unknown_Count_Perc_Diff"
          ),
          value: "ddavP_nasal_Unknown_Count_Perc_Diff",
          aling: "center",
        },
        //==================== DDAVP IV ====================
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_DDAVP_IV_Total"
          ),
          value: "current_DDAVP_IV_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_DDAVP_IV_Total"
          ),
          value: "previous_DDAVP_IV_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_IV_Count_Perc_Diff"
          ),
          value: "ddavP_IV_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_DDAVP_IV_Male"
          ),
          value: "current_DDAVP_IV_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_DDAVP_IV_Male"
          ),
          value: "previous_DDAVP_IV_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_IV_Male_Count_Perc_Diff"
          ),
          value: "ddavP_IV_Male_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_DDAVP_IV_Female"
          ),
          value: "current_DDAVP_IV_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_DDAVP_IV_Female"
          ),
          value: "previous_DDAVP_IV_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_IV_Female_Count_Perc_Diff"
          ),
          value: "ddavP_IV_Female_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_DDAVP_IV_Unknown"
          ),
          value: "current_DDAVP_IV_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_DDAVP_IV_Unknown"
          ),
          value: "previous_DDAVP_IV_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.ddavP_IV_Unknown_Count_Perc_Diff"
          ),
          value: "ddavP_IV_Unknown_Count_Perc_Diff",
          aling: "center",
        },
        //==================== Tranexamic Acid ====================
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Tranexamic_acid_Total"
          ),
          value: "current_Tranexamic_acid_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Tranexamic_acid_Total"
          ),
          value: "previous_Tranexamic_acid_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.tranexamic_acid_Count_Perc_Diff"
          ),
          value: "tranexamic_acid_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Tranexamic_acid_Male"
          ),
          value: "current_Tranexamic_acid_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Tranexamic_acid_Male"
          ),
          value: "previous_Tranexamic_acid_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.tranexamic_acid_Male_Count_Perc_Diff"
          ),
          value: "tranexamic_acid_Male_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Tranexamic_acid_Female"
          ),
          value: "current_Tranexamic_acid_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Tranexamic_acid_Female"
          ),
          value: "previous_Tranexamic_acid_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.tranexamic_acid_Female_Count_Perc_Diff"
          ),
          value: "tranexamic_acid_Female_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Tranexamic_acid_Unknown"
          ),
          value: "current_Tranexamic_acid_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Tranexamic_acid_Unknown"
          ),
          value: "previous_Tranexamic_acid_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.tranexamic_acid_Unknown_Count_Perc_Diff"
          ),
          value: "tranexamic_acid_Unknown_Count_Perc_Diff",
          aling: "center",
        },
        //==================== Hormonal Therapy ====================
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Hormonal_Therapy_Total"
          ),
          value: "current_Hormonal_Therapy_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Hormonal_Therapy_Total"
          ),
          value: "previous_Hormonal_Therapy_Total",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.hormonal_Therapy_Count_Perc_Diff"
          ),
          value: "hormonal_Therapy_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Hormonal_Therapy_Male"
          ),
          value: "current_Hormonal_Therapy_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Hormonal_Therapy_Male"
          ),
          value: "previous_Hormonal_Therapy_Male",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.hormonal_Therapy_Male_Count_Perc_Diff"
          ),
          value: "hormonal_Therapy_Male_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Hormonal_Therapy_Female"
          ),
          value: "current_Hormonal_Therapy_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Hormonal_Therapy_Female"
          ),
          value: "previous_Hormonal_Therapy_Female",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.hormonal_Therapy_Female_Count_Perc_Diff"
          ),
          value: "hormonal_Therapy_Female_Count_Perc_Diff",
          aling: "center",
        },

        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.current_Hormonal_Therapy_Unknown"
          ),
          value: "current_Hormonal_Therapy_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.previous_Hormonal_Therapy_Unknown"
          ),
          value: "previous_Hormonal_Therapy_Unknown",
          aling: "center",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t(
            "reports.ddcreports.vWDProductUse.hormonal_Therapy_Unknown_Count_Perc_Diff"
          ),
          value: "hormonal_Therapy_Unknown_Count_Perc_Diff",
          aling: "center",
        },
      ];
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.payload.year = this.yearSelected;
      axios
        .get("report/DDC/Review-vWDProductsUse", {
          params: { year: this.yearSelected },
        })
        .then((res: any) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(`report/ddc/Review-vWDProductsUse/export`, {
          params: { year: this.yearSelected },
          responseType: "blob",
        })
        .then((res) => {
          const fileName =
            `${i18n.t("reports.ddcreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.yearSelected) {
      this.getData();
    }
  },
  watch: {
    yearSelected() {
      this.getData();
    },
  },
});
